import React, { useEffect, useState } from "react"

import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

import "./contact.scss"

const ContactPage = ({ location }) => {
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const { search } = location
    if (search) {
      const query = search.slice(1).split("=")
      if (query[0] === "completed" && query[1] === "true") {
        setCompleted(true)
      }
    }
  }, [location])

  return (
    <Layout>
      <SEO
        description="Let us know if you have any comments, suggestions, or questions.
            We'd love to hear from you!"
        title="Contact"
      />
      <section className="container">
        <div className="contact">
          <h1>How can we help?</h1>
          <p>
            Let us know if you have any comments, suggestions, or questions.
            We'd love to hear from you!
          </p>
          {completed && (
            <p className="result">
              Thanks for reaching out! We'll get back to you shortly.
            </p>
          )}
          <form
            className="contact-form"
            name="contact"
            method="post"
            action="/contact/?completed=true"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field">
              <label htmlFor="name">Name</label>
              <input
                className="input"
                type="text"
                name="name"
                id="name"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                className="input"
                type="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                className="input"
                name="message"
                id="message"
                rows="6"
                required
              />
            </div>
            <input className="btn" type="submit" value="Submit" />
          </form>
        </div>
      </section>
    </Layout>
  )
}
export default ContactPage
